import React from 'react';
import { Pie } from 'react-chartjs-2';

const TypesPieChart = (typeCount, issue) => {

    const data = {
        labels: [],
        datasets: [
            {
                label: "Types",
                data: [],
                backgroundColor: [],
                borderColor: [],
                borderWidth: 1
            }
        ]
    }

    if (typeCount !== undefined && typeCount.typeCount !== undefined) {
        Object.entries(typeCount.typeCount).map(([issue, counts]) => {
            if (issue === "Blades") {
                Object.entries(counts).map(([type, count]) => {
                    data.labels.push(type)
                    data.datasets[0].data.push(count)
                    var color = '#' + Math.random().toString(16).substr(-6);
                    data.datasets[0].backgroundColor.push(color)
                    data.datasets[0].borderColor.push(color)
                })
            }
        })

        const options = {
            legend: {
                display: false
            },
            options: {
                title: {
                    display: false,
                    text: ''
                },
                legend: {
                    display: false
                },
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem) {
                            return tooltipItem.yLabel;
                        }
                    }
                }
            }
        }

        return (
            <Pie
                width={400}
                height={400}
                data={data}
                options={options}
            />
        )
    } else return (<p>loading</p>)
}

export default TypesPieChart;