import { FilterList, FilterListItem, useDataProvider } from 'react-admin';
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined';
import React, { useState, useEffect } from 'react';

const TypeFilter = () => {
    const [state, setState] = useState({})
    const dataProvider = useDataProvider();
    useEffect(() => {
        if (state.typeCount === undefined) {
            dataProvider.getOne("annotations/count", { id: "type" })
                .then(({ data }) => {
                    setState(state => ({
                        ...state,
                        typeCount: data.counts
                    }))
                })
                .catch(error => {
                    console.log(error)
                })
        }
    })
    return (
        <FilterList
            label="Type"
            icon={<LocalOfferIcon />}
        >
            {state.typeCount !== undefined && Object.entries(state.typeCount).map(([issue, counts]) => (
                issue === "Blades" && Object.entries(counts).map(([type, count]) => (
                    <FilterListItem
                        label={type}
                        key={count + type}
                        value={{ type: type }}
                    />
                ))
            ))}
        </FilterList>
    )
};

export default TypeFilter;