import * as React from "react";
import {
    Button,
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
} from 'react-admin';
import ClearIcon from '@material-ui/icons/Clear';

const Refuse = ({ selectedIds }) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const [updateMany, { loading }] = useUpdateMany(
        'annotations/refuse',
        selectedIds,
        { views: 0 },
        {
            onSuccess: () => {
                refresh();
                notify('Annotations refused', 'success');
                unselectAll('annotations/pending');
            },
            onFailure: error => {
                notify(error, 'warning');
            }
        }
    );

    return (
        <Button
            label="Refuse"
            disabled={loading}
            onClick={updateMany}
        >
            <ClearIcon />
        </Button>
    );
};

export default Refuse;
