import * as React from "react";
import { Admin, Resource } from 'react-admin';
import { UserList } from './dataTypes/Users';
import { AnnotationsList } from "./dataTypes/Annotations"
import { ProdAnnotationsList } from "./dataTypes/ProdAnnotations"
import { ClustersList } from "./dataTypes/Clusters"
import jsonServerProvider from 'ra-data-json-server';
import './App.css'

//import authProvider from './authProvider';
import {
  FirebaseDataProvider,
  FirebaseAuthProvider
} from "react-admin-firebase";
import Dashboard from './dashboard/Dashboard';
import { firebaseConfig as config } from './FIREBASE_CONFIG';

const options = {
  logging: true,
  rootRef: 'root_collection/some_document'
}
//const firestoreProvider = FirebaseDataProvider(config, options);
const authProvider = FirebaseAuthProvider(config, options);
const dataProvider = jsonServerProvider('');

const App = () => (
  <Admin dashboard={Dashboard} dataProvider={dataProvider} authProvider={authProvider}>
    <Resource name="annotations/admin" options={{ label: 'ML Annotations' }} list={AnnotationsList}/>
    <Resource name="annotations/pending" options={{ label: 'Pending Annotations' }} list={AnnotationsList}/>
    {/*
    <Resource name="users/dev" options={{ label: 'Dev Users' }} list={UserList} />
    <Resource name="users/prod" options={{ label: 'Prod Users' }} list={UserList} />
    <Resource name="users/admin" options={{ label: 'Admin Users' }} list={UserList} />
    */}
    <Resource name="annotations/prod" options={{ label: "Prod Annotations"}} list={ProdAnnotationsList}/>
    <Resource name="clusters/list" options={{ label: "Clusters"}} list={ClustersList}/>
  </Admin>
);

export default App;
