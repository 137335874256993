
import React, { useState, useEffect, useCallback, FC, CSSProperties, } from 'react';
import { useMediaQuery, Theme } from '@material-ui/core';
import { useVersion, useDataProvider, useSafeSetState } from 'react-admin';
import { Card, CardContent, CardHeader, Box } from '@material-ui/core';
import TotalCount from "./TotalCount"
import TypesPieChart from './TypesPieChart';
import StatusPieChart from "./StatusPieChart"
import ClustersPieChart from "./ClustersPieChart"
import '../App.css'


const Dashboard = () => {
    const [state, setState] = useState({})
    const dataProvider = useDataProvider();
    /*const fetchCount = useCallback(async () => {
        const { data: totalCount } = await dataProvider.getOne('annotations/count', {id: 'all'});
        setTotalCount(totalCount)
    })
    useEffect(() => {
        fetchCount();
    });*/
    useEffect(() => {
        dataProvider.getOne('annotations/count', { id: "all" })
            .then(({ data }) => {
                console.log(data.totalCount)
                setState(state => ({
                    ...state,
                    totalCount: data.totalCount
                }))
            })
            .catch(error => {
                console.log(error)
            })
        dataProvider.getOne("annotations/count", { id: "type" })
            .then(({ data }) => {
                console.log(data.counts)
                setState(state => ({
                    ...state,
                    typeCount: data.counts
                }))
            })
            .catch(error => {
                console.log(error)
            })
        dataProvider.getOne("annotations/count", { id: "status" })
            .then(({ data }) => {
                console.log(data.counts)
                setState(state => ({
                    ...state,
                    statusCount: data.counts
                }))
            })
            .catch(error => {
                console.log(error)
            })
        dataProvider.getOne("annotations/count", { id: "clusters" })
            .then(({ data }) => {
                console.log(data.counts)
                setState(state => ({
                    ...state,
                    clustersCount: data.counts
                }))
            })
            .catch(error => {
                console.log(error)
            })
    }, []);
    return (
        <Box display="flex" flexDirection="column" p={2}>
            <Box display="flex" flexDirection="row">
                <Card className="clustersPieChart">
                    <CardHeader title="Clusters" />
                    <CardContent>
                        <ClustersPieChart clusterCount={state.clustersCount} issue={"Blades"} />
                    </CardContent>
                </Card>
                <Card className="typesPieChart">
                    <CardHeader title="Types" />
                    <CardContent>
                        <TypesPieChart typeCount={state.typeCount} issue={"Blades"} />
                    </CardContent>
                </Card>
                <Card className="statusPieChart">
                    <CardHeader title="Status 2" />
                    <CardContent>
                        <StatusPieChart statusCount={state.statusCount} issue={"Blades"} />
                    </CardContent>
                </Card>
            </Box>
        </Box>
    )
};

export default Dashboard;