import { FilterList, FilterListItem, useDataProvider } from 'react-admin';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import React, { useState, useEffect } from 'react';

const ClusterFilter = () => {
    const [state, setState] = useState({})
    const dataProvider = useDataProvider();
    useEffect(() => {
        dataProvider.getOne('clusters/getNames', { id: "all" })
            .then(({ data }) => {
                setState(state => ({
                    ...state,
                    clusters: data.clusters
                }))
            })
            .catch(error => {
                console.log(error)
            })
    })
    return (
        <FilterList
            label="Cluster"
            icon={<GroupWorkIcon />}
        >      
            {state.clusters !== undefined && state.clusters.map((label) => <FilterListItem
                label={label}
                key={label}
                value={{ cluster: label}}
                />
            )}         
        </FilterList>
    )
};

export default ClusterFilter;