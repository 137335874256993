import { List, Datagrid, TextField, ImageField, ShowContextProvider, Button } from 'react-admin';
import React, { useState, useEffect, useCallback, FC, CSSProperties, } from 'react';
import { useVersion, useDataProvider, useSafeSetState } from 'react-admin';
import Accept from '../buttons/Accept';
import Refuse from "../buttons/Refuse";
import AssignCluster from "../buttons/AssignCluster";
import Modal from '@material-ui/core/Modal';
import { Card as MuiCard, CardContent, withStyles } from '@material-ui/core';
import TypeFilter from "./filters/TypeFilter"
import ClusterFilter from "./filters/ClusterFilter"
import { makeStyles } from '@material-ui/core/styles';

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 800,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));


const PostBulkActionButtons = props => (
    <React.Fragment>
        <AssignCluster label="Assign" {...props} />
        {/*<Accept label="Approve" {...props} />*/}
        <Refuse label="Refuse" {...props} />
    </React.Fragment>
);

const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);

const FilterSidebar = () => (
    <Card>
        <CardContent>
            <ClusterFilter />
            <TypeFilter />
        </CardContent>
    </Card>
);


export const AnnotationsList = (props) => {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const [url, setURL] = React.useState("")
    const [photoId, setPhotoId] = React.useState('')
    const [annot, setAnnot] = React.useState({})
    const dataProvider = useDataProvider();
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function showPhoto(id, basePath, annot) {
        if (annot !== undefined) {
            var origin = annot.origin
            dataProvider.getOne('annotations/getPhoto', { id: annot.inspectionId + "/" + annot.photoId + "/" + origin })
                .then((data) => {
                    console.log(data)
                    console.log(data.data.url)
                    setURL(data.data.url)
                    setPhotoId("DJI_" + annot.photoId + ".JPG")
                    setAnnot(annot)
                    handleOpen()
                })
        }
    }

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h2 id="simple-modal-title">{photoId}</h2>
            <p id="simple-modal-description">
                <div style={{position: "relative"}}>
                    <img id="currImg" src={url} width="800" height="600"></img>
                        <div id="tmpDefect"
                            style={{
                                top: annot.y + "%",
                                left: annot.x + "%",
                                width: annot.width + "%",
                                height: annot.height + "%",
                                border: "dashed",
                                borderColor: "red",
                                position: "absolute",
                                borderWidth: "2px",
                                zIndex: 3,
                            }}
                        />
                </div>
            </p>
        </div>
    );

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"

            >
                {body}
            </Modal>
            <List {...props} aside={<FilterSidebar />} exporter={false} style={{ tableLayout: 'fixed' }} bulkActionButtons={<PostBulkActionButtons />}>
                <Datagrid rowClick={showPhoto}>
                    <TextField source="inspectionId" />
                    <TextField source="photoId" />
                    <TextField source="annotationId" />
                    <TextField source="origin" />
                    <TextField source="type" />
                    <TextField source="width" />
                    <TextField source="height" />
                    <ImageField source="url" onClick={showPhoto} />
                </Datagrid>
            </List>
        </>
    )
}