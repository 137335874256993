import React from 'react';
import { Pie } from 'react-chartjs-2';

const StatusPieChart = (statusCount, issue) => {

    const colors = [""]

    const data = {
        labels: [],
        datasets: [
            {
                label: "Status",
                data: [],
                backgroundColor: [],
                borderColor: [],
                borderWidth: 1
            }
        ]
    }

    if (statusCount !== undefined && statusCount.statusCount !== undefined) {
        Object.entries(statusCount.statusCount).map(([issue, counts]) => {
            if (issue === "Blades") {
                Object.entries(counts).map(([status, number]) => {
                    data.labels.push(status)
                    data.datasets[0].data.push(number)
                    var color
                    switch(status){
                        case "pending": {color = "#DADA23";break;}
                        case "accepted": {color = "#27DA23";break;}
                        case "refused": {color= "#CE300E";break;}
                    }
                    data.datasets[0].backgroundColor.push(color)
                    data.datasets[0].borderColor.push(color)
                })
            }
        })

        return (
            <Pie
                data={data}
            />
        )
    } else return (<p>loading</p>)
}

export default StatusPieChart;