import * as React from "react";
import $ from "jquery"
import { List, Datagrid, TextField, NumberField, DeleteButton, ShowButton, CreateButton, TopToolbar, Button } from 'react-admin';
import Modal from '@material-ui/core/Modal';
import { Card as MuiCard, CardContent, withStyles } from '@material-ui/core';
import { useVersion, useDataProvider, useSafeSetState, useRefresh, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 800,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export const ClustersList = props => {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const [cluster, setCluster] = React.useState("");
    const [mode, setMode] = React.useState("")
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();

    const handleOpenDelete = (id, basePath, annot) => {
        setCluster(id)
        setMode("delete")
        setOpen(true);
    };

    const handleOpenCreate = () => {
        setMode("create")
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const confirmDelete = () => {
        dataProvider.getOne('clusters/delete', { id: cluster })
            .then((data) => {
                console.log(data)
                handleClose()
                refresh()
                notify('Cluster deleted', 'success');
            })
    }

    const createCluster = () => {
        let newName = $('#newName').val()
        dataProvider.getOne('clusters/create', { id: newName })
            .then((data) => {
                console.log(data)
                handleClose()
                refresh()
                notify('Cluster created', 'success');
            })
    }

    const deletebody = (
        <div style={modalStyle} className={classes.paper}>
            <h2 id="simple-modal-title">About to delete cluster {cluster}</h2>
            <p id="simple-modal-description">
                Are you sure you want to delete this cluster ?
            </p>
            <p>
                <Button variant='contained' size="medium" color="secondary" label="Confirm" onClick={confirmDelete} />
                <Button variant='contained' size="medium" color="secondary" label="Cancel" onClick={handleClose} />
            </p>
        </div>
    );
    const createbody = (
        <div style={modalStyle} className={classes.paper}>
            <h2 id="simple-modal-title">Create a new cluster</h2>
            <input id="newName" type="text" />
            <p>
                <Button variant='contained' size="medium" color="secondary" label="Create" onClick={createCluster} />
                <Button variant='contained' size="medium" color="secondary" label="Cancel" onClick={handleClose} />
            </p>
        </div>
    );
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"

            >
                {mode === "delete" ?
                    deletebody : createbody
                }
            </Modal>
            <TopToolbar>
                <CreateButton onClick={handleOpenCreate} />
            </TopToolbar>
            Click on a row to delete the cluster...
            <List {...props} exporter={false}>
                <Datagrid rowClick={handleOpenDelete}>
                    <TextField source="name" />
                    <NumberField source="count" />
                </Datagrid>
            </List>
        </>
    )
};