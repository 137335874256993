import { FilterList, FilterListItem, useDataProvider } from 'react-admin';
import ErrorIcon from '@material-ui/icons/Error';
import React, { useState, useEffect } from 'react';

const SeverityFilter = () => {
    return (
        <FilterList
            label="Severity"
            icon={<ErrorIcon />}
        >
            <FilterListItem
                label={"1"}
                key={1}
                value={{ severity: 1 }}
            />
            <FilterListItem
                label={"2"}
                key={2}
                value={{ severity: 2 }}
            />
            <FilterListItem
                label={"3"}
                key={3}
                value={{ severity: 3 }}
            />
            <FilterListItem
                label={"4"}
                key={4}
                value={{ severity: 4 }}
            />
            <FilterListItem
                label={"5"}
                key={5}
                value={{ severity: 5 }}
            />
        </FilterList>
    )
};

export default SeverityFilter;