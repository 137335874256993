import $ from "jquery"
import {
    Button,
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
} from 'react-admin';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import React, { useState, useEffect, useCallback, FC, CSSProperties, } from 'react';
import { useVersion, useDataProvider, useSafeSetState } from 'react-admin';

const AssignCluster = ({ selectedIds }) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const [cluster, setCluster] = useState("Voids")
    const [state, setState] = useState({})
    const dataProvider = useDataProvider();
    useEffect(() => {
        dataProvider.getOne('clusters/getNames', { id: "all" })
            .then(({ data }) => {
                setState(state => ({
                    ...state,
                    clusters: data.clusters
                }))
            })
            .catch(error => {
                console.log(error)
            })
    })
    const [updateMany, { loading }] = useUpdateMany(
        'annotations/assign/' + cluster,
        selectedIds,
        { views: 0 },
        {
            onSuccess: () => {
                refresh();
                notify('Annotations accepted and assigned to cluster : ' + cluster, 'success');
                unselectAll('annotations/pending');
                unselectAll('annotations/admin');
            },
            onFailure: error => {
                notify(error, 'warning');
            }
        }
    );

    return (
        <>
            <Button
                label="Assign to"
                disabled={loading}
                onClick={updateMany}
            >
                <GroupWorkIcon />
            </Button>
            {state.clusters !== undefined &&
                <select id="clusterSelect" onChange={() => { setCluster($('#clusterSelect').val()); console.log($('#clusterSelect').val()) }}>
                    {state.clusters.map((label) => <option key={label} value={label} >{label}</option>)}
                </select>
            }
        </>
    );
};

export default AssignCluster;
