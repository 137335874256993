import { List, Datagrid, TextField, ImageField, ShowContextProvider } from 'react-admin';
import React, { useState, useEffect, useCallback, FC, CSSProperties, } from 'react';
import { useVersion, useDataProvider, useSafeSetState } from 'react-admin';
import Modal from '@material-ui/core/Modal';
import { Card as MuiCard, CardContent, withStyles } from '@material-ui/core';
import SeverityFilter from "./filters/SeverityFilter"
import { makeStyles } from '@material-ui/core/styles';

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 800,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));


const PostBulkActionButtons = props => (
    <React.Fragment>

    </React.Fragment>
);

const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);

const FilterSidebar = () => (
    <Card>
        <CardContent>
            <SeverityFilter/>
        </CardContent>
    </Card>
);


export const ProdAnnotationsList = (props) => {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const [url, setURL] = React.useState("")
    const [photoId, setPhotoId] = React.useState('')
    const dataProvider = useDataProvider();
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function showPhoto(id, basePath, annot) {
        if (annot !== undefined) {
            var origin = annot.origin
            dataProvider.getOne('annotations/getPhoto', { id: annot.inspectionId + "/" + annot.photoId + "/" + origin })
                .then((data) => {
                    console.log(data)
                    console.log(data.data.url)
                    setURL(data.data.url)
                    setPhotoId("DJI_" + annot.photoId + ".JPG")
                    handleOpen()
                })
        }
    }

    function downloadPhoto(id, basePath, annot) {
        if (annot !== undefined) {
            dataProvider.getOne('annotations/getPhoto', { id: annot.inspectionId + "/" + annot.photoId + "/prod" })
                .then((data) => {
                    var url = data.data.url
                    Image.prototype.load = function (url) {
                        var thisImg = this;
                        var xmlHTTP = new XMLHttpRequest();
                        xmlHTTP.open('GET', url, true);
                        xmlHTTP.responseType = 'arraybuffer';
                        xmlHTTP.onload = function (e) {
                            let blob = new Blob([this.response]);
                            thisImg.src = window.URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob)
                            link.setAttribute(
                                'download',
                                "DJI_" + annot.photoId + ".jpg",
                            );

                            link.setAttribute(
                                'target',
                                "_blank",
                            );

                            link.setAttribute(
                                "rel",
                                "noopener noreferrer"
                            )

                            // Append to html link element page
                            document.body.appendChild(link);

                            // Start download
                            link.click();

                            // Clean up and remove the link
                            link.parentNode.removeChild(link);
                        };
                        xmlHTTP.send();

                    }
                    var img = new Image();
                    img.load(url);
                })
        }
    }

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h2 id="simple-modal-title">{photoId}</h2>
            <p id="simple-modal-description">
                <img src={url} width="800" height="600" />
            </p>
        </div>
    );

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"

            >
                {body}
            </Modal>
            <List {...props} aside={<FilterSidebar />} exporter={false} style={{ tableLayout: 'fixed' }} bulkActionButtons={<PostBulkActionButtons />}>
                <Datagrid rowClick={downloadPhoto}>
                    <TextField source="severity" />
                    <TextField source="inspectionId" />
                    <TextField source="photoId" />
                    <TextField source="annotationId" />
                    <TextField source="type" />
                    <ImageField source="url" onClick={showPhoto} />
                </Datagrid>
            </List>
        </>
    )
}